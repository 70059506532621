import { HeapService } from './core/services/heap.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { ClubConfigurationService } from './core/services/club-configuration.service';

import { NgRedux, DevToolsExtension } from '@angular-redux-ivy/store';
import { initializeStore } from './app.redux';
import { IAppState } from './models/data.interfaces';
import { SalesFunnelActionType, ILoadQuoteAction } from './store/reducer';

import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { SignInBannerComponent } from './components/sign-in-banner/sign-in-banner.component';
import { SignInModalContentComponent } from './components/sign-in-modal/sign-in-modal.component';
import { CoverageTypeComponent } from './components/coverage-type/coverage-type.component';
import { MultiTripComponent } from './components/coverage-type/multi-trip/multi-trip.component';
import { AmaHeaderComponent } from './components/site-header/ama-header/ama-header.component';
import { OtherHeaderComponent } from './components/site-header/other-header/other-header.component';
import { AtaHeaderComponent } from './components/site-header/ata-header/ata-header.component';
import { SingleTripComponent } from './components/coverage-type/single-trip/single-trip.component';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { SvgDefinitionsComponent } from './svg-icons/svg-definitions/svg-definitions.component';
import { PolicyMatrixComponent } from './components/policy-matrix/policy-matrix.component';
import { EligibilityRequirementsModalComponent } from './components/policy-matrix/eligibility-requirements-modal.component';
import { TravellerInformationComponent } from './components/traveller-information/traveller-information.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TripDetailsComponent } from './components/trip-details/trip-details.component';
import { TripDetailsSummaryComponent } from './components/trip-details-summary/trip-details-summary.component';
import { ReviewQuoteComponent } from './components/review-quote/review-quote.component';
import { ConfirmedPolicyComponent } from './components/confirmed-policy/confirmed-policy.component';
import { ConfirmedDetailsComponent } from './components/confirmed-details/confirmed-details.component';
import { SiteLayoutComponent } from './components/_layout/site-layout/site-layout.component';
import { RecommendedPlanComponent } from './components/recommended-plan/recommended-plan.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { ActivityDealCardsComponent } from './components/activity-deal-cards/activity-deal-cards.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

import { TripDetailsQuoteOptionRetrievalMiddleware } from './store/trip-details-quote-option-retrieval.middleware';
import { LoadQuoteComponent } from './components/load-quote/load-quote.component';
import { SaveQuoteComponent, SaveQuoteModalContentComponent } from './components/save-quote/save-quote.component';
import { CountriesService } from './core/services/countries.service';
import { environment } from 'src/environments/environment';
import { VisitorsUpsellMatrixComponent } from './components/visitors-upsell-matrix/visitors-upsell-matrix.component';
import { UpsellMatrixComponent } from './components/upsell-matrix/upsell-matrix.component';
import { MonerisConfigurationService } from './core/services/moneris-configuration.service';
import { UnexpectedServerErrorComponent } from './components/unexpected-server-error/unexpected-server-error.component';
import { UserService } from './core/services/user.service';
import { SelectProvinceModalComponent } from './components/select-province-modal/select-province-modal.component';
import { NumberOfTravellersComponent } from './components/number-of-travellers/number-of-travellers.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { QuestionnaireModalContentComponent } from './components/questionnaire-modal/questionnaire-modal.component';
import { TravellerAgesComponent } from './components/traveller-ages/traveller-ages.component';
import { PosternComponent } from './components/postern/postern.component';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { LoadingIndicatorsModule } from './components/loading-indicators/loading-indicators.module';
import { MembershipNumberComponent } from './components/traveller-information/membership-number/membership-number.component';
import { TravellerConditionsComponent } from './components/traveller-conditions/traveller-conditions.component';
import { AppInsights } from 'applicationinsights-js';
import { RenewQuoteComponent } from './components/renew-quote/renew-quote.component';
import { RenewPolicyDetailsComponent } from './components/renew-policy-details/renew-policy-details.component';
import { InfobarComponent } from './components/infobar/infobar.component';
import { RenewTripDetailsComponent } from './components/renew-trip-details/renew-trip-details.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SessionTimeoutModalComponent, SessionTimeoutService } from './components/session-timeout-modal/session-timeout-modal';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivationEnd } from '@angular/router';
import { AppHttpInterceptor } from './app.http.interceptor';
import { EventModalComponent } from './components/event-modal/event-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AtaFooterComponent } from './components/site-footer/ata-footer/ata-footer.component';
import { MainPageLayoutComponent } from './components/_layout/main-page-layout/main-page-layout.component';
import { MemberJoinWidgetComponent } from './components/member-join-widget/member-join-widget.component';
import { DiscountModalComponent } from './components/member-join-widget/discount-info-modal/discount-modal.component';
import { BannerModule } from './components/tst-widget/banner/banner.module';
import { ExclusiveSavingComponent } from './components/exclusive-saving-message/exclusive-saving-message.component';
import { MemberJoinSectionComponent } from './components/member-join-section/member-join-section.component';
import { TooltipModalComponent } from './components/policy-matrix/modals/tooltip-modal/tooltip-modal.component';

const appInitializer = (
  clubConfigService: ClubConfigurationService,
  countriesService: CountriesService,
  configService: MonerisConfigurationService,
  userService: UserService,
  translateService: TranslateService
): (() => Promise<void>) => {
  return async () => {
    try {
      await Promise.all([clubConfigService.getConfig(), clubConfigService.getServerConfig()]);
      // Set the translations before attempting the rest of the initialization
      translateService.setDefaultLang('en');
      translateService.use('en');
      await Promise.all([countriesService.initialize(), configService.initialize(), userService.initialize()]);
    } catch (e) {
      AppInsights.trackException(new Error(`Error initializing application: ${e.message}`));
      AppInsights.flush();

      // Let's add a CSS class to the body to indicate that it is a failed load.
      window.document.body.classList.add('load-failed');

      // To prevent Angular from continuing to bootstrap
      const forever = new Promise(() => {}); // will never resolve
      await forever;
    }
  };
};

@NgModule({
  declarations: [
    AppComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    AtaFooterComponent,
    SignInBannerComponent,
    SignInModalContentComponent,
    CoverageTypeComponent,
    ExclusiveSavingComponent,
    MemberJoinSectionComponent,
    MainPageLayoutComponent,
    MemberJoinWidgetComponent,
    MultiTripComponent,
    AmaHeaderComponent,
    OtherHeaderComponent,
    AtaHeaderComponent,
    SingleTripComponent,
    SvgIconsComponent,
    SvgDefinitionsComponent,
    TravellerInformationComponent,
    MembershipNumberComponent,
    PaymentComponent,
    PageNotFoundComponent,
    PolicyMatrixComponent,
    EligibilityRequirementsModalComponent,
    TripDetailsComponent,
    TripDetailsSummaryComponent,
    ReviewQuoteComponent,
    ConfirmedPolicyComponent,
    ConfirmedDetailsComponent,
    SiteLayoutComponent,
    RecommendedPlanComponent,
    WizardComponent,
    LoadQuoteComponent,
    SaveQuoteComponent,
    SaveQuoteModalContentComponent,
    VisitorsUpsellMatrixComponent,
    UpsellMatrixComponent,
    UnexpectedServerErrorComponent,
    SelectProvinceModalComponent,
    NumberOfTravellersComponent,
    ActivityDealCardsComponent,
    StarRatingComponent,
    QuestionnaireModalContentComponent,
    TravellerAgesComponent,
    PosternComponent,
    ErrorModalComponent,
    TravellerConditionsComponent,
    RenewQuoteComponent,
    RenewPolicyDetailsComponent,
    InfobarComponent,
    RenewTripDetailsComponent,
    SessionTimeoutModalComponent,
    EventModalComponent,
    DiscountModalComponent,
    TooltipModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    LoadingIndicatorsModule,
    BannerModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [ClubConfigurationService, CountriesService, MonerisConfigurationService, UserService, TranslateService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    store: NgRedux<IAppState>,
    devTools: DevToolsExtension,
    tripDetailsQuoteOptionRetrievalMiddleware: TripDetailsQuoteOptionRetrievalMiddleware,
    translateService: TranslateService,
    sessionTimeoutService: SessionTimeoutService,
    router: Router,
    private heapService: HeapService
  ) {
    initializeStore(store, devTools, tripDetailsQuoteOptionRetrievalMiddleware, false);

    // The confirmation popup is controlled by the individual routes
    let showConfirmationPopup = false;
    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        showConfirmationPopup = event.snapshot.data.showConfirmationPopup;
      }
    });

    // The popup should be hidden during development
    if (environment.production) {
      // Keep track of the last element clicked since the beforeunload handler
      // does not have access to the click target.
      let lastClicked = null;
      window.addEventListener('click', (e: any) => {
        e = e || window.event;
        lastClicked = e.target;
      });
      const handler = (e: any) => {
        // Ignore telephone links for mobile devices, when clicking on SVGs the substring function doesn't exist
        if (lastClicked && lastClicked.href && lastClicked.href.substring && lastClicked.href.substring(0, 4) === 'tel:') {
          // After clicking on a link a user might refresh their page so we should not
          // be holding on to the reference anymore.
          lastClicked = null;
          return;
        }
        if (showConfirmationPopup === true && !sessionTimeoutService.hasTimedOut) {
          e.preventDefault();
          e.returnValue = translateService.instant('refresh_modal.message');
          return e.returnValue;
        }
      };
      // Chrome/IE
      window.addEventListener('beforeunload', handler);
      // Firefox
      window.addEventListener('onbeforeunload', handler);
    }

    // During testing it's useful to modify the state during runtime to see changes between quotes.
    if (!environment.production) {
      window['loadReduxState'] = (key: string) => {
        store.dispatch({
          type: SalesFunnelActionType.LOAD_QUOTE,
          payload: JSON.parse(localStorage.getItem(key)),
        } as ILoadQuoteAction);
      };
    }

    this.heapService.insertHeapScript();
  }
}
