import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoverageTypeComponent } from './components/coverage-type/coverage-type.component';
import { TravellerInformationComponent } from './components/traveller-information/traveller-information.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TripDetailsComponent } from './components/trip-details/trip-details.component';
import { ReviewQuoteComponent } from './components/review-quote/review-quote.component';
import { ConfirmedPolicyComponent } from './components/confirmed-policy/confirmed-policy.component';
import { SiteLayoutComponent } from './components/_layout/site-layout/site-layout.component';
import { TripDetailsSummaryComponent } from './components/trip-details-summary/trip-details-summary.component';
import { LoadQuoteComponent } from './components/load-quote/load-quote.component';
import { FunnelRoutes } from './models/funnel-routes.enum';
import { UpsellMatrixComponent } from './components/upsell-matrix/upsell-matrix.component';
import { UnexpectedServerErrorComponent } from './components/unexpected-server-error/unexpected-server-error.component';
import { PosternComponent } from './components/postern/postern.component';
import { InitialRouteGuard } from './core/guards/initial-route.guard';
import { RenewQuoteComponent } from './components/renew-quote/renew-quote.component';
import { RenewPolicyDetailsComponent } from './components/renew-policy-details/renew-policy-details.component';
import { InfobarComponent } from './components/infobar/infobar.component';
import { RenewTripDetailsComponent } from './components/renew-trip-details/renew-trip-details.component';
import { MainPageLayoutComponent } from './components/_layout/main-page-layout/main-page-layout.component';

export const MAIN = 'content-main';
export const HEADER = 'content-header';
export const SIDEBAR = 'content-sidebar';
export const FOOTER = 'content-footer';

const guards = [InitialRouteGuard];

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/coverage',
    data: {
      step: FunnelRoutes.coverage,
    },
    pathMatch: 'full',
  },
  {
    path: 'load-quote',
    data: {
      step: FunnelRoutes.loadQuote,
    },
    component: SiteLayoutComponent,
    children: [{ path: '', component: LoadQuoteComponent, outlet: MAIN }],
    canActivate: guards,
  },
  {
    path: 'postern',
    data: {
      step: FunnelRoutes.postern,
    },
    component: PosternComponent,
    canActivate: guards,
  },
  {
    path: 'coverage',
    data: {
      step: FunnelRoutes.coverage,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: MainPageLayoutComponent, outlet: MAIN },
      { path: '', component: InfobarComponent, data: { path: 'coverage.header' }, outlet: HEADER },
    ],
    canActivate: guards,
  },
  {
    path: 'trip-details',
    data: {
      step: FunnelRoutes.tripDetails,
      showConfirmationPopup: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: TripDetailsComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
      { path: '', component: UpsellMatrixComponent, outlet: FOOTER },
    ],
    canActivate: guards,
  },

  {
    path: 'review-quote',
    data: {
      step: FunnelRoutes.reviewQuote,
      showConfirmationPopup: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: ReviewQuoteComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
    ],
    canActivate: guards,
  },
  {
    path: 'traveller-info',
    data: {
      step: FunnelRoutes.travellerInfo,
      showConfirmationPopup: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: TravellerInformationComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
    ],
    canActivate: guards,
  },
  {
    path: 'payment',
    data: {
      step: FunnelRoutes.payment,
      showConfirmationPopup: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: PaymentComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
    ],
    canActivate: guards,
  },
  {
    path: 'policy-confirmation',
    data: {
      step: FunnelRoutes.policyConfirmation,
    },
    component: SiteLayoutComponent,
    children: [{ path: '', component: ConfirmedPolicyComponent, outlet: MAIN }],
    canActivate: guards,
  },
  {
    path: 'renew',
    data: {
      step: FunnelRoutes.retrievePolicy,
      renew: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: RenewQuoteComponent, outlet: MAIN },
      { path: '', component: InfobarComponent, data: { path: 'renew_quote.header' }, outlet: HEADER },
    ],
    canActivate: guards,
  },
  {
    path: 'renew/policy-details',
    data: {
      step: FunnelRoutes.policyDetails,
      showConfirmationPopup: true,
      renew: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: RenewPolicyDetailsComponent, outlet: MAIN },
      { path: '', component: InfobarComponent, data: { path: 'renew_policy_details.header' }, outlet: HEADER },
    ],
    canActivate: guards,
  },
  {
    path: 'renew/trip-details',
    data: {
      step: FunnelRoutes.tripDetails,
      showConfirmationPopup: true,
      renew: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: RenewTripDetailsComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
      { path: '', component: UpsellMatrixComponent, outlet: FOOTER },
    ],
    canActivate: guards,
  },
  {
    path: 'renew/review-quote',
    data: {
      step: FunnelRoutes.reviewQuote,
      showConfirmationPopup: true,
      renew: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: ReviewQuoteComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
    ],
    canActivate: guards,
  },
  {
    path: 'renew/traveller-info',
    data: {
      step: FunnelRoutes.travellerInfo,
      showConfirmationPopup: true,
      renew: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: TravellerInformationComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
    ],
    canActivate: guards,
  },
  {
    path: 'renew/payment',
    data: {
      step: FunnelRoutes.payment,
      showConfirmationPopup: true,
      renew: true,
    },
    component: SiteLayoutComponent,
    children: [
      { path: '', component: PaymentComponent, outlet: MAIN },
      { path: '', component: TripDetailsSummaryComponent, outlet: SIDEBAR },
    ],
    canActivate: guards,
  },
  {
    path: 'not-found',
    data: {
      step: FunnelRoutes.pageNotFound,
    },
    component: SiteLayoutComponent,
    children: [{ path: '', component: PageNotFoundComponent, outlet: MAIN }],
    canActivate: guards,
  },
  {
    path: 'unexpected-server-error',
    data: {
      step: FunnelRoutes.unexpectedServerError,
    },
    component: SiteLayoutComponent,
    children: [{ path: '', component: UnexpectedServerErrorComponent, outlet: MAIN }],
    canActivate: guards,
  },
  {
    path: '**',
    data: {
      step: FunnelRoutes.pageNotFound,
    },
    component: SiteLayoutComponent,
    children: [{ path: '', component: PageNotFoundComponent, outlet: MAIN }],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'disabled',
      scrollOffset: [0, 0],
    }),
  ],
  providers: [InitialRouteGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
