<div class="row my-5">
    <div class="col-12 col-md-6">
        <p class="h3 mb-3 lato-black text-capitalize">{{ 'coverage.exclusive_saving.title' | translate }}</p>
        <div [innerHTML]="'coverage.exclusive_saving.paragraph' | translate | safeHtml"></div>
    </div>
    <div class="col-12 col-md-6">
        <ul class="exclusive-saving-list">
            <li>
                {{ 'coverage.exclusive_saving.bullets.bullet1' | translate }}
            </li>
            <li>
                {{ 'coverage.exclusive_saving.bullets.bullet2' | translate }}
            </li>
            <li>
                {{ 'coverage.exclusive_saving.bullets.bullet3' | translate }} <br />
                <span class="mice_text">{{ 'coverage.exclusive_saving.mice_text' | translate }}</span>
            </li>
        </ul>

    </div>
</div>