import { InsuranceProduct } from '../../models/products';
import { IPolicyDetails } from 'src/app/models/app-config.model';
export interface IPolicyMatrix {
  coverageTypeId: CoverageType;
  tables: IPolicyMatrixTable[];
}

export interface IPolicyMatrixTable {
  tableClass: string;
  tableTitle: string;
  tableDescription?: string;
  tableBodyRows: any[];
  columnCount?: number;
  columnDetails: IPolicyMatrixColDetails[];
}

export interface IPolicyMatrixColDetails {
  colProductCode: string;
  colTitle: string;
  colSummaryTitle: string;
  colSummarySubtitle: string;
  colButtonText: string;
}

export enum CoverageType {
  SINGLE = 1,
  MULTI = 2,
  CANCEL = 3,
  SINGLE_TRIP_CANADA_ONLY = 4,
}

export class SingleTripMultiTripDataBuilder {
  public comparisonMappings: any[];
  public pmSingleTripData: IPolicyMatrixTable;
  public pmMultiTripData: IPolicyMatrixTable;
  public pmTripCancelData: IPolicyMatrixTable;
  public pmEmergencyMedicalData: IPolicyMatrixTable;
  public pmCanadaOnlyMedicalData: IPolicyMatrixTable;
  public pmSingleTripPremium: IPolicyMatrixTable;

  constructor(policy_details: IPolicyDetails[], insuredValue?: number) {
    this.comparisonMappings = [
      {
        coverageType: CoverageType.SINGLE,
        renderTables: ['single-trip', 'multi-trip'],
      },
      {
        coverageType: CoverageType.MULTI,
        renderTables: ['multi-trip'],
      },
      {
        coverageType: CoverageType.CANCEL,
        renderTables: ['trip-cancellation', 'single-trip-premium'],
      },
      {
        coverageType: CoverageType.SINGLE_TRIP_CANADA_ONLY,
        renderTables: ['single-trip', 'canada-only'],
      },
    ];

    this.pmSingleTripData = {
      tableClass: 'single-trip',
      tableTitle: 'policy_matrix.single_trip_table_title',
      tableBodyRows: this.buildRowData(
        [InsuranceProduct.SingleTripMedicalPlan, InsuranceProduct.SingleTripPremiumPackage],
        policy_details,
        insuredValue
      ),
      columnDetails: [
        {
          colProductCode: InsuranceProduct.SingleTripMedicalPlan,
          colTitle: 'policy_matrix.medical_column_title',
          colSummaryTitle: 'policy_matrix.medical_summary_title',
          colSummarySubtitle: 'policy_matrix.member_price',
          colButtonText: 'policy_matrix.medical_button_text',
        },
        {
          colProductCode: InsuranceProduct.SingleTripPremiumPackage,
          colTitle: 'policy_matrix.medical_and_travel_column_title',
          colSummaryTitle: 'policy_matrix.medical_and_travel_summary_title',
          colSummarySubtitle: 'policy_matrix.member_price',
          colButtonText: 'policy_matrix.medical_and_travel_button_text',
        },
      ],
    };

    this.pmMultiTripData = {
      tableClass: 'multi-trip',
      tableTitle: 'policy_matrix.multi_trip_table_title',
      tableBodyRows: this.buildRowData([InsuranceProduct.MultiTripMedicalPlan, InsuranceProduct.AnnualPremiumPackage], policy_details),
      columnDetails: [
        {
          colProductCode: InsuranceProduct.MultiTripMedicalPlan,
          colTitle: 'policy_matrix.medical_column_title',
          colSummaryTitle: 'policy_matrix.medical_summary_title',
          colSummarySubtitle: 'policy_matrix.member_price',
          colButtonText: 'policy_matrix.medical_button_text',
        },
        {
          colProductCode: InsuranceProduct.AnnualPremiumPackage,
          colTitle: 'policy_matrix.medical_and_travel_column_title',
          colSummaryTitle: 'policy_matrix.medical_and_travel_summary_title',
          colSummarySubtitle: 'policy_matrix.best_value',
          colButtonText: 'policy_matrix.medical_and_travel_button_text',
        },
      ],
    };

    this.pmTripCancelData = {
      tableClass: 'trip-cancellation',
      tableTitle: 'policy_matrix.trip_cancellation_table_title',
      tableBodyRows: this.buildRowData(
        [InsuranceProduct.TripCancellationAndTripInterruptionInsurance, InsuranceProduct.NonMedicalPremiumPackage],
        policy_details
      ),
      columnDetails: [
        {
          colProductCode: InsuranceProduct.TripCancellationAndTripInterruptionInsurance,
          colTitle: 'policy_matrix.trip_cancellation_column_title',
          colSummaryTitle: 'policy_matrix.trip_cancellation_summary_title',
          colSummarySubtitle: 'policy_matrix.member_price',
          colButtonText: 'policy_matrix.trip_cancellation_button_text',
        },
        {
          colProductCode: InsuranceProduct.NonMedicalPremiumPackage,
          colTitle: 'policy_matrix.non_medical_column_title',
          colSummaryTitle: 'policy_matrix.non_medical_summary_title',
          colSummarySubtitle: 'policy_matrix.member_price',
          colButtonText: 'policy_matrix.non_medical_button_text',
        },
      ],
    };

    this.pmEmergencyMedicalData = {
      tableClass: 'emergency-medical',
      tableTitle: 'policy_matrix.emergency_medical_table_title',
      tableBodyRows: this.buildRowData([InsuranceProduct.VisitorsToCanadaMedicalPlan], policy_details),
      columnDetails: [
        {
          colProductCode: InsuranceProduct.VisitorsToCanadaMedicalPlan,
          colTitle: 'policy_matrix.emergency_medical_column_title',
          colSummaryTitle: 'policy_matrix.emergency_medical_summary_title',
          colSummarySubtitle: 'policy_matrix.best_value',
          colButtonText: 'policy_matrix.emergency_medical_button_text',
        },
      ],
    };

    this.pmSingleTripPremium = {
      tableClass: 'single-trip-premium',
      tableTitle: 'policy_matrix.emergency_medical_table_title',
      tableBodyRows: this.buildRowData([InsuranceProduct.SingleTripPremiumPackage], policy_details, insuredValue),
      columnDetails: [
        {
          colProductCode: InsuranceProduct.SingleTripPremiumPackage,
          colTitle: 'policy_matrix.emergency_medical_column_title',
          colSummaryTitle: 'policy_matrix.emergency_medical_summary_title',
          colSummarySubtitle: 'policy_matrix.best_value',
          colButtonText: 'policy_matrix.emergency_medical_button_text',
        },
      ],
    };

    this.pmCanadaOnlyMedicalData = {
      tableClass: 'canada-only',
      tableTitle: 'policy_matrix.canada_only_table_title',
      tableBodyRows: this.buildRowData([InsuranceProduct.CanadaMedicalPlan, InsuranceProduct.CanadaPackagePlan], policy_details),
      columnDetails: [
        {
          colProductCode: InsuranceProduct.CanadaMedicalPlan,
          colTitle: 'policy_matrix.medical_column_title',
          colSummaryTitle: 'policy_matrix.medical_summary_title',
          colSummarySubtitle: 'policy_matrix.member_price',
          colButtonText: 'policy_matrix.medical_button_text',
        },
        {
          colProductCode: InsuranceProduct.CanadaPackagePlan,
          colTitle: 'policy_matrix.medical_and_travel_column_title',
          colSummaryTitle: 'policy_matrix.medical_and_travel_summary_title',
          colSummarySubtitle: 'policy_matrix.best_value',
          colButtonText: 'policy_matrix.medical_and_travel_button_text',
        },
      ],
    };
  }

  buildRowData(products: string[], policy_details: IPolicyDetails[], insuredValue?: number): any[] {
    const returnData = [];

    (policy_details || []).forEach((detail) => {
      const rowData = [];
      rowData.push(detail.description);
      rowData.push(this.checkForDisplayData(detail, products[0], insuredValue));
      if (products.length > 1) {
        rowData.push(this.checkForDisplayData(detail, products[1], insuredValue));
      }
      returnData.push({ rowData: rowData });
    });
    return returnData;
  }

  checkForDisplayData(detail: IPolicyDetails, product: string, insuredValue?: number): any {
    let text: string = detail.display_text && detail.display_text[product];
    let focusOnClick = false;

    if (text?.includes('{{amount}}')) {
      text = text.replace('{{amount}}', (insuredValue ?? 0).toString());
      focusOnClick = true;
    }

    return {
      product,
      applies: detail.product_list.includes(product),
      text,
      tooltip: detail.display_tooltip && detail.display_tooltip[product],
      focusOnClick,
    };
  }
}
